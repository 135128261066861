<template>
  <div class="header mt-md-5 mb-0" :class="headerClass" id="header">
    <div class="header-body border-bottom-0 pb-0" :class="headerBodyClass">
      <div class="row align-items-center">
        <div class="col">
          <p class="text-muted text-paragraph">{{ subtitle }}</p>

          <!-- TITLE -->
          <h1 class="d-flex align-items-center" :class="{ 'header-subtitle-custom': isSubHeader }">
            <span v-if="iconClass" :class="iconClass" class="me-3"></span>
            <slot v-if="$slots.iconImage" name="iconImage"></slot>
            <span class="me-3 mb-3">{{ title }}</span>
            <span v-if="totalCount !== null" class="badge bg-secondary-soft" style="font-size: 0.8rem;">{{ totalCount
            }}</span>
          </h1>

        </div>
        <div v-if="btnLabel" class="col-auto">
          <router-link v-if="btnLink" :to="btnLink" class="btn btn-primary lift">
            <i v-if="btnIcon" :class="btnIcon" class="me-1"></i>
            {{ btnLabel }}
          </router-link>
          <button v-else @click="handleBtnAction()" class="btn btn-primary lift">
            <i v-if="btnIcon" :class="btnIcon" class="me-1"></i>
            {{ btnLabel }}
          </button>
        </div>
        <div class="col-auto">
          <slot name="button"></slot>
        </div>
      </div>

      <!-- TABS -->
      <div class="row align-items-center" v-if="tabs">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item text-paragraph m-0" v-for="(item, index) of tabs" v-bind:key="index">
              
              <p v-if="item.disabled" 
              class="nav-link nav-square text-uppercase fw-bold h4 px-4 py-3 text-muted cursor-pointer bg-dark-soft" disabled>
                {{ item.title }}
              </p>
              
              <p v-else @click.prevent="handleTabClicked(item)" 
              class="nav-link nav-square text-uppercase text-white fw-bold h4 px-4 py-3 cursor-pointer"
                :class="{ active: item.active, 'bg-primary': item.active }">
                {{ item.title }}
              </p>

            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    subtitle: String,
    btnLabel: String,
    btnLink: String,
    btnAction: Function,
    tabClicked: Function,
    btnIcon: String,
    tabs: Array,
    iconClass: String,
    iconImage: String,
    headerClass: String,
    headerBodyClass: String,
    subtitle: String,
    totalCount: Number,
    isSubHeader: {
      type: Boolean,
      default: true
    },
    col: {
      type: String,
      default: 'col-10'
    }
  },
  methods: {
    handleBtnAction() {
      this.$emit('btnAction')
    },
    handleTabClicked(item) {
      this.$emit('tabClicked', item)
    }
  }
}
</script>

<style>
.header-subtitle-custom {
  font-size: 1.4rem !important;
}
</style>
