<template>
  <div class="form-group text-start" id="password_input">
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label ">
        {{ label }} <i v-if="required" class="text-danger">*</i>
      </label>
      <slot name="labelContentEnd"></slot>
    </div>
    <!-- <label class="form-label text-dark text-label"> {{ label }} </label> -->

    <div class="input-group input-group-merge">
      <input v-model="model.$model" :class="{ 'is-invalid': model.$error }" class="form-control border-right-0"
        :placeholder="placeholder" :type="passwordFieldType" />
      <span class="input-group-text cursor-pointer" :class="{ 'border-danger': model.$error }">
        <i @click="togglePassword()" class="fas" :class="{
          'fa-eye': passwordFieldType == 'text',
          'fa-eye-slash': passwordFieldType == 'password',
        }"></i>
      </span>
      <div class="invalid-feedback">
        <small>{{ errorMsg }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    model: {
      default: {}
    },
    label: {
      type: String,
      default: 'Password'
    },
    placeholder: {
      type: String,
      default: 'Enter your password'
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      passwordFieldType: 'password'
    }
  },
  methods: {
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType == 'password' ? 'text' : 'password'
    }
  }
}
</script>
