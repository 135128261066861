const paths = {
    landing: {
        home: '/',
        exam: {
            list: '/simulado',
            detail: '/simulado/detalhes'
        },
        about: '/quem-somos',
        contact: '/contato',
        approveds: '/aprovados',
        benefits: '/benefícios',
        bundles: {
            list: '/turmas',
            detail: '/turmas/detalhes'
        }
    },
    dashboard: {
        home: '/painel',
        login: '/painel/login',
        passwordRecovery: '/painel/esqueci-minha-senha',
        register: '/painel/cadastrar',
        payment: '/painel/pagamento',
        exam: '/painel/simulado',
        bundle: '/painel/turma',
        passwordReset: '/password/reset',
        myAccount: '/minha-conta',
        passwordUpdate: '/alterar-senha'
    },
    not_found: '/not-found',
}

export default paths