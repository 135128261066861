import IBundle from '@/interfaces/IBundle'
import IExam from '@/interfaces/IExam'
import ExamsService from './ExamsService'
import NetworkService, { Endpoint } from './NetworkService'

export default class BundlesService {
    private network
    private examService

    constructor() {
        this.network = new NetworkService()
        this.examService = new ExamsService()
    }

    list = async (params: object = {}) => {
        return this.network.get(Endpoint.bundles, params)
    }
    listMyBundles = async (params: object = {}) => {
        return this.network.get(Endpoint.myBundles, params)
    }
    show = async (id: number | string) => {
        return this.network.get(`${Endpoint.bundles}/${id}`)
    }
    showMyBundle = (id: string) => {
        return this.network.get(`${Endpoint.myBundles}/${id}`)
    }
    prepareBundle(data: any) {
        console.log('AEBA', JSON.stringify(data, null, 4))
        data.benefitsList = data.benefits ? data.benefits.split('|') : []
        data.formattedPrice = data.price.toCurrency().replace('R$', '')

        let finishedExamsLength = 0
        if (data.simulations) {
            data.formattedExams = data.simulations.map((elem: IExam) => {
                if (elem.simulation_finished) finishedExamsLength++
                return this.examService.prepareExam(elem)
            })
        }

        if (data.simulations_coming_soons) {
            const comingSoonExams = data.simulations_coming_soons.map((elem: any) => ({ ...this.examService.prepareComingSoonExam(elem), type: 'coming_soon' }))
            data.formattedExams = [...comingSoonExams, ...data.formattedExams]
        }

        if (data.bonus_pdf) {
            data.formattedPdfs = data.bonus_pdf.map((elem: any) => {
                const urlSplit = elem.url.split('/')
                return { ...elem, title: urlSplit[urlSplit.length - 1], downloadUrl: `${process.env.VUE_APP_API_BASE_URL}bundlespdfs/${elem.id}` }
            })
        }

        data.finishedExamsLength = finishedExamsLength
        data.examsLength = data.simulations.length + data.simulations_coming_soons.length
        data.finishedExamsPercentage = (finishedExamsLength * 100 / data.examsLength).toFixed(2)

        return data as IBundle
    }
}