
interface String {
    capitalizeFirst(): String;
    limitString(): String;
    clear(): String | string;
    formatCPF(): String;
}
String.prototype.capitalizeFirst = function () {
    let res = this
    try {
        res = this.charAt(0).toUpperCase() + this.slice(1);
    } catch (e) {
        console.log(e)
    } finally {
        return res
    }
}
String.prototype.limitString = function (limit = 40) {
    let res = this
    try {
        res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this
    } catch (e) {
        console.log('erro', e)
        return res
    }
    finally {
        return res
    }
}
String.prototype.clear = function () {
    // let res = this
    // try {
    //     res = this.replace(/[^\w\s]/gi, '').replace(' ', '').replace(/[_-]/g, "")
    // } catch (e) {
    //     return res
    // }
    // finally {
    //     return res
    // }
    let res = this
    try {
        res = this.replace(/[^\dA-Za-z]/g, '').replace(/[_-]/g, "")
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}
String.prototype.formatCPF = function () {
    let res = this
    try {
        // Remove qualquer caractere que não seja número
        res = res.replace(/\D/g, "");
        // Aplica a formatação
        res = res.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } catch (error) {
        return res
    } finally {
        return res
    }
}
