import paths from './paths'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/Landing/Home/Home.vue')
  // },
  {
    path: paths.landing.home,
    name: 'home',
    // alias: '/',
    component: () => import('@/views/Landing/Home/Home.vue'),
  },
  {
    path: paths.landing.about,
    name: 'about',
    component: () => import('@/views/Landing/About/About.vue'),
  },
  {
    path: paths.landing.bundles.list,
    name: 'bundles',
    component: () => import('@/views/Landing/Bundles/Bundles.vue'),
  },
  {
    path: paths.landing.approveds,
    name: 'approveds',
    component: () => import('@/views/Landing/Approveds/Approveds.vue'),
  },
  {
    path: paths.landing.benefits,
    name: 'benefits',
    component: () => import('@/views/Landing/Benefits/Benefits.vue'),
  },
  {
    path: paths.landing.contact,
    name: 'contact',
    component: () => import('@/views/Landing/Contact/Contact.vue'),
  },
  {
    path: `${paths.landing.exam.detail}/:id`,
    name: 'exam',
    component: () => import('@/views/Landing/Bundles/ExamDetail/ExamDetail.vue'),
  },
  {
    path: `${paths.landing.bundles.detail}/:id`,
    name: 'bundleDetail',
    component: () => import('@/views/Landing/Bundles/BundleDetail/BundleDetail.vue'),
  },
  {
    path: paths.dashboard.login,
    name: 'login',
    component: () => import('@/views/Dashboard/Login/Login.vue'),
  },
  {
    path: `${paths.dashboard.register}/:id?`,
    name: 'register',
    component: () => import('@/views/Dashboard/Register/Register.vue'),
  },
  {
    path: `${paths.dashboard.payment}/:id`,
    name: 'payment',
    component: () => import('@/views/Dashboard/Payment/Payment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.dashboard.passwordRecovery,
    name: 'passwordRecovery',
    component: () => import('@/views/Dashboard/PasswordRecovery/PasswordRecovery.vue'),
  },
  {
    path: paths.dashboard.passwordReset,
    name: 'passwordReset',
    component: () => import('@/views/Dashboard/PasswordReset/PasswordReset.vue'),
  },
  {
    path: paths.dashboard.passwordUpdate,
    name: 'passwordUpdate',
    component: () => import('@/views/Dashboard/PasswordUpdate/PasswordUpdate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.dashboard.myAccount,
    name: 'myAccount',
    component: () => import('@/views/Dashboard/MyAccount/MyAccount.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.dashboard.home,
    name: 'dashboardHome',
    component: () => import('@/views/Dashboard/Home/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.dashboard.exam}/:id/:product_id?/:path/:attempt?`,
    name: 'dashboardExam',
    component: () => import('@/views/Dashboard/Exam/Exam.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.dashboard.bundle}/:id/:product_id?`,
    name: 'dashboardBundle',
    component: () => import('@/views/Dashboard/Bundle/Bundle.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.not_found,
    name: 'notFound',
    component: () => import('@/views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'notFound'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Se houver uma posição de scroll salva, retorne-a
    if (savedPosition) {
      return savedPosition;
    } else {
      // Caso contrário, retorne ao topo
      return { top: 0 };
    }
  }
})



router.beforeEach((to, from, next) => {
  const isLoggedIn = !!store.state.session;
  if (to.meta.requiresAuth && !isLoggedIn) {
    next(paths.dashboard.login);
  } else {
    next();
  }
});

export default router
