
import ILogin from '@/interfaces/ILogin'
import { MUTATIONS, store } from '@/store'
import NetworkService, { Endpoint } from './NetworkService'
import ISession from '@/interfaces/ISession'
import ExamsService from './ExamsService'
import IMyExam from '@/interfaces/IMyExam'

export default class UserService {
  private network
  private examsService
  constructor() {
    this.network = new NetworkService()
    this.examsService = new ExamsService()
  }
  list = (params: any) => {
    return this.network.get(Endpoint.users, params)
  }
  create = (params: any) => {
    return this.network.post(Endpoint.users, params)
  }
  show = (id: number) => {
    return this.network.get(`${Endpoint.users}/${id}`)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.users}/${id}`)
  }
  update = (id: number, params: any) => {
    return this.network.put(`${Endpoint.users}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (res.data.isAdmin) {
      throw ({ status: false, message: 'Credenciais inválidas' })
    } else {
      return res
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
    // return this.network.post(Endpoint.logout, null);
  }
  passwordRecovery = (params: any) => {
    return this.network.post(Endpoint.passwordRecovery, params);
  };
  resetPassword = (params: any) => {
    return this.network.post(Endpoint.passwordReset, params);
  }
  getTerms = (key: string) => {
    return this.network.get(`${Endpoint.settings}/${key}`);
  }
  createCardToken = async (params: any) => {
    const options = {
      method: 'POST',
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      body: JSON.stringify({
        card: params,
        type: 'card'
      })
    };

    return fetch(`https://api.pagar.me/core/v5/tokens?appId=${process.env.VUE_APP_PAGARME_PUBLIC_KEY}`, options)
      .then(response => response.json())
      .catch(err => this.network.handleError(err))

    // try {
    //   let response = await fetch(`https://api.pagar.me/core/v5/tokens?appId=${process.env.VUE_APP_PAGARME_PUBLIC_KEY}`, options)
    //   return response.json()
    // } catch (err) {
    //   this.network.handleError(err)
    // }
  }
  createCard = (params: object) => {
    return this.network.post(Endpoint.paymentCard, params);
  }
  buyExam = (params: object) => {
    return this.network.post(Endpoint.paymentBuy, params);
  }
  saveSession = (data: any, access_token: string | null = null) => {
    const session: ISession = {
      id: data.id,
      name: data.name,
      nickname: data.nickname,
      email: data.email,
      phone_number: data.phone_number,
      profile_picture: data.profile_picture,
      document: data.document,
      phone_area: data.phone_area,
      created_at: data.created_at,
    }
    store.commit(MUTATIONS.SAVE_SESSION, session)

    if (access_token) {
      store.commit(MUTATIONS.SAVE_TOKEN, access_token)
    }
  }
  syncSession = async () => {
    const session = store.state.session
    if (session) {
      const res = await this.show(session.id)
      console.log(res)
      this.saveSession(res.data)
    }
  }
  listCreditCards = () => {
    return this.network.get(Endpoint.paymentCard)
  }
  deleteCreditCard = (id: number) => {
    return this.network.delete(`${Endpoint.paymentCard}/${id}`)
  }
  isMyExam = async (id: string) => {
    const session = store.state.session
    if (session) {
      const res = await this.examsService.listMyExams()
      const isMyExam = res.data.some((elem: IMyExam) => elem.simulation_id == Number(id) && (elem.status == 'adquirido' || elem.status == 'pendente'))
      if (isMyExam) {
        alert('Você já possui esse simulado')
        return true
      }
    }
    return false
  }
  getAddressFromCep = async (cep: string) => {
    return fetch(`https://viacep.com.br/ws/${cep}/json`).then((r) => r.json());
  }
  updateProfilePicture(params: any) {
    return this.network.postMultipart(Endpoint.updateProfilePicture, params)
  }
}
