<template>
  <div class="bg-light">
    <Container customClass="col-12 col-lg-10" class="">
      <footer
        class="d-flex flex-wrap justify-content-between align-items-center py-6 border-top"
      >
        <!-- Social Icons -->
        <ul class="nav col-md-4">
          <li class="list-inline-item list-social-item me-3">
            <a
              href="https://www.facebook.com/p/Borracho-Concursos-100092627692634/"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-brands fa-facebook text-white fa-2x me-1"></i>
            </a>
          </li>
          <li class="list-inline-item list-social-item me-3">
            <a
              href="https://www.instagram.com/borracho.oficial?igsh=YjJzamQ4cjFiY3px&utm_source=qr"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-brands fa-instagram text-white fa-2x me-1"></i>
            </a>
          </li>
          <li class="list-inline-item list-social-item me-3">
            <a
              href="https://youtube.com/@borrachoconcursos?si=Raj7NvEg96lljllV"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-brands fa-youtube text-white fa-2x me-1"></i>
            </a>
          </li>
          <li class="list-inline-item list-social-item">
            <a
              href="https://t.me/borrachoconcursos"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-brands fa-telegram text-white fa-2x me-1"></i>
            </a>
          </li>
        </ul>

        <!-- Logo Icon -->
        <div
          class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto"
        >
          <img
            src="@/assets/img/logo-primary.svg"
            alt="logo"
            height="26"
            class="footer-brand mb-3 rounded"
          />
        </div>

        <!-- Links -->
        <ul class="nav col-md-4 justify-content-end">
          <li class="nav-item h3">
            <router-link :to="$paths.landing.home" class="nav-link px-2">
              Home
            </router-link>
          </li>
          <li class="nav-item h3">
            <router-link :to="$paths.landing.contact" class="nav-link px-2">
              Contato
            </router-link>
          </li>
          <li class="nav-item h3">
            <router-link :to="$paths.dashboard.home" class="nav-link px-2">
              Login
            </router-link>
          </li>
        </ul>
      </footer>
    </Container>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    async handleFreeExam() {
      try {
        await this.$examsService.getFreeExam();
      } catch (error) {
        console.log(error);
      }
    },
  },
});
</script>
