<template>
  <ConfirmationAlert />
  <NotificationToast />
  <router-view :class="{ 'padding-top-fix': !noNavbar }" />
</template>
<script>
import NotificationToast from "@/components/NotificationToast";
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    console.log("Versão:", process.env.VUE_APP_VERSION_STRING);
  },
  computed: {
    noNavbar() {
      return [
        "login",
        "passwordRecovery",
        "register",
        "payment",
        "passwordReset",
      ].includes(this.$route.name);
    },
  },
  created() {
    document.title = "Borracho";
  },
  components: {
    NotificationToast,
  },
});
</script>

<style>
.padding-top-fix {
  padding-top: 88px;
}
</style>
