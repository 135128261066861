import { Modal } from "bootstrap";
import { defineComponent } from "vue";

export interface IConfirmationAlertOptions {
  title: string
  message: string
  confirmCallback: () => void
  cancelCallback: () => void
  confirmationButtonLabel: string
}

export default defineComponent({
  name: "ConfirmationAlert",
  mounted() {
    this.$eventBus.$on(
      "showConfirmationAlert",
      ({
        title,
        message,
        confirmCallback,
        cancelCallback,
        confirmationButtonLabel,
      }: IConfirmationAlertOptions) => {
        this.confirmCallback = confirmCallback;
        this.cancelCallback = cancelCallback;
        this.message = message;
        this.title = title;
        this.confirmationButtonLabel = confirmationButtonLabel;
        const element = document.getElementById("confirmAlertModal")

        this.confirmAlertModal = new Modal(
          element as Element,
          {
            backdrop: "static",
            keyboard: false,
          }
        );
        this.confirmAlertModal.toggle();
      }
    );
  },
  data() {
    return {
      confirmAlertModal: {} as Modal,
      confirmCallback: () => { },
      cancelCallback: () => { },
      title: '',
      message: '',
      confirmationButtonLabel: "Sim",
    };
  },
  methods: {
    alertClosed() {
      this.cancelAction();
    },
    confirmAction() {
      this.confirmCallback();
      this.confirmAlertModal.toggle();
    },
    cancelAction() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      this.confirmAlertModal.toggle();
    },
  },
  destroyed() { },
});
