<template>
  <template v-if="!readOnly">
    <TextInput
      :label="label"
      :model="model"
      type="text"
      placeholder="https://www.youtube.com/watch?v=6iFbuIpe68k&t=7s"
      :errorMsg="errorMsg"
    />
  </template>
  <div>
    <label v-if="readOnly" class="form-label text-muted"> {{ label }}</label>
    <iframe
      class="my-2"
      v-if="videoLink"
      width="100%"
      height="600"
      :src="videoLink"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      style="border-radius: 15px"
    ></iframe>
  </div>
</template>
<script>
import YoutubeInputController from "./YoutubeInputController";
export default YoutubeInputController;
</script>
